import * as React from "react"

const Header = ({ siteTitle }) => (
    <div className="mt-12 md:mb-12">
        <h1 className="text-5xl md:text-6xl font-bold relative font-title">
            Welcome to Stars for Fans
        </h1>
    </div>

)
export default Header
